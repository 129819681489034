import Form, { FormField } from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { TenantStatus, TenantType } from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { ChangeEvent, MouseEvent, useState } from "react";
import { Input } from "../../../types";
import Select from "../../../ui-lib/components/Select";
import copyText from "../copyText";

type Tenant = {
  id: string;
  name: string;
  status: TenantStatus;
  type: TenantType;
};

interface Props {
  isInternalMode?: boolean;
  isOpen: boolean;
  isProcessing: boolean;
  selectedTenant?: Tenant;
  onInteraction: (interaction: TenantForm.Interaction) => void;
}

interface State {
  nameInput: Input<string>;
  statusInput: Input<TenantStatus | string>;
  typeInput: Input<TenantType | string>;
}

function TenantForm(props: Props): JSX.Element {
  const isUpdateMode = !!props.selectedTenant;

  const initialState = {
    nameInput: props.selectedTenant
      ? { value: props.selectedTenant.name, isValid: true, hasChanged: false }
      : { value: "", isValid: false, hasChanged: false },
    statusInput: props.selectedTenant
      ? { value: props.selectedTenant.status, isValid: true, hasChanged: false }
      : { value: "", isValid: true, hasChanged: false },
    typeInput: {
      value: "",
      isValid: !isUpdateMode && props.isInternalMode ? false : true,
      hasChanged: false,
    },
  };

  const [state, setState] = useState<State>(initialState);

  const mergeState = getMergeState(setState);

  //
  // Interaction Handlers
  //

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let isValid = false;
    let hasChanged = false;

    switch (name) {
      case "name": {
        isValid = value.length > 0;
        hasChanged = value !== props.selectedTenant?.name;
        break;
      }
      case "status": {
        isValid = value.length > 0;
        hasChanged = value !== props.selectedTenant?.status;
        break;
      }
      case "type": {
        isValid = value.length > 0;
        hasChanged = value !== props.selectedTenant?.type;
        break;
      }
    }

    mergeState({ [`${name}Input`]: { value, isValid, hasChanged } });
  }

  function handleSubmitUpdate(event: MouseEvent) {
    event.preventDefault();

    if (!props.selectedTenant) return;

    props.onInteraction({
      type: TenantForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE,
      tenantID: props.selectedTenant.id,
      ...(state.nameInput.hasChanged ? { name: state.nameInput.value } : {}),
      ...(state.statusInput.hasChanged
        ? { status: state.statusInput.value as TenantStatus }
        : {}),
    });
  }

  function handleSubmitCreate(event: MouseEvent): void {
    event.preventDefault();

    // If in Internal Admin, user can select a type.
    // If in MSP Admin, type defaults to MSP_CUSTOMER.
    props.onInteraction({
      type: TenantForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE,
      name: state.nameInput.value,
      status: state.statusInput.value as TenantStatus,
      tenantType: props.isInternalMode
        ? (state.typeInput.value as TenantType)
        : TenantType.MSP_CUSTOMER,
    });
  }

  //
  // Validations
  //

  const everyIsValid = Object.values(state).every(
    (input: Input) => input.isValid
  );

  const someHasChanged = Object.values(state).some(
    (input: Input) => input.hasChanged
  );

  const canSubmit = isUpdateMode
    ? everyIsValid && someHasChanged
    : everyIsValid;

  const typeOptions = [
    TenantType.DIRECT_CUSTOMER,
    TenantType.INTERNAL,
    TenantType.MSP_PARENT,
  ].map((type) => ({
    label: type,
    value: type,
  }));

  const statusOptions: { label: string; value: TenantStatus }[] = [
    { label: copyText.tenantStatusLabel_ACTIVE, value: TenantStatus.ACTIVE },
    {
      label: copyText.tenantStatusLabel_ACTIVE_POV,
      value: TenantStatus.ACTIVE_POV,
    },
  ];

  // Should be able to update to INACTIVE if needed.
  if (isUpdateMode) {
    statusOptions.push({
      label: copyText.tenantStatusLabel_INACTIVE,
      value: TenantStatus.INACTIVE,
    });
  }

  const selectedStatusOption = statusOptions.find(
    (option) => option.value === state.statusInput.value
  );

  const selectedTypeOption = typeOptions.find(
    (option) => option.value === state.typeInput.value
  );

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen
      showCloseButton
      onClose={() =>
        props.onInteraction({
          type: TenantForm.INTERACTION_CANCEL_BUTTON_CLICKED,
        })
      }
    >
      <Modal.Header>
        <Text appearance="h4">
          {isUpdateMode ? copyText.formTitleUpdate : copyText.formTitleCreate}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FormField
            name="name"
            disabled={props.isProcessing}
            input={TextInput}
            label={copyText.nameInputLabel}
            value={state.nameInput.value}
            onChange={handleChange}
          />
          {props.isInternalMode && !isUpdateMode && (
            <FormField label={copyText.typeInputLabel}>
              <Select
                options={typeOptions}
                value={selectedTypeOption}
                onChange={(option) =>
                  handleChange({
                    target: { name: "type", value: option?.value },
                  } as ChangeEvent<HTMLInputElement>)
                }
              />
            </FormField>
          )}
          <FormField label={copyText.statusInputLabel}>
            <Select
              options={statusOptions}
              value={selectedStatusOption}
              onChange={(option) =>
                handleChange({
                  target: { name: "status", value: option?.value },
                } as ChangeEvent<HTMLInputElement>)
              }
            />
          </FormField>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!canSubmit || props.isProcessing}
          primary
          type="submit"
          width={100}
          onClick={isUpdateMode ? handleSubmitUpdate : handleSubmitCreate}
        >
          {props.isProcessing ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

TenantForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  "TenantForm.INTERACTION_CANCEL_BUTTON_CLICKED" as const;
TenantForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE =
  "TenantForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE" as const;
TenantForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE =
  "TenantForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE" as const;

type InteractionCancelButtonClicked = {
  type: typeof TenantForm.INTERACTION_CANCEL_BUTTON_CLICKED;
};

type InteractionSubmitButtonClickedCreate = {
  type: typeof TenantForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE;
  name: string;
  status: TenantStatus;
  tenantType: TenantType;
};

type InteractionSubmitButtonClickedUpdate = {
  type: typeof TenantForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE;
  tenantID: string;
  name?: string;
  status?: TenantStatus;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace TenantForm {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionSubmitButtonClickedCreate
    | InteractionSubmitButtonClickedUpdate;
}

export default TenantForm;
