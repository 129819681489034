import { useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import {
  CloudProviderType,
  ServiceType,
} from "@ternary/api-lib/constants/enums";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Link from "@ternary/api-lib/ui-lib/components/Link";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React, { useMemo } from "react";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { getInsightsUrl } from "../../committed-use/gcp/utils";
import copyText from "../copyText";
import { MeasureCell } from "@ternary/api-lib/ui-lib/charts/Table/MeasureCell";
import { UnitType } from "@ternary/api-lib/constants/analytics";

type Recommendation = {
  id: string;
  tenantDocID: string;
  cloudProviderType: CloudProviderType;
  estimateCurrency: string;
  estimateValue: number;
  number: string;
  serviceType: ServiceType;
  tenantName: string;
};

type TableData = Recommendation & { hasTenantAccess: boolean };

const columnHelper = createColumnHelper<TableData>();

interface Props {
  isLoading: boolean;
  recommendations: Recommendation[];
  onInteraction: (interaction: MspUsageRecommendationTable.Interaction) => void;
}

export function MspUsageRecommendationTable(props: Props) {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor("tenantName", {
        cell: (cell) => {
          const tenantDocID = cell.row.original.tenantDocID;
          const number = cell.row.original.number;
          const serviceType = cell.row.original.serviceType;
          const hasAccess = cell.row.original.hasTenantAccess;

          return hasAccess ? (
            <Link
              target="_blank"
              to={{
                pathname: getInsightsUrl(serviceType),
                search: `?tenant_id=${tenantDocID}&tab=optimizations&id=${number}`,
              }}
            >
              {cell.getValue()}
            </Link>
          ) : (
            <Text
              appearance="link"
              color={theme.link_color}
              onClick={() =>
                props.onInteraction({
                  type: MspUsageRecommendationTable.INTERACTION_LINK_CLICKED,
                  tenantDocID,
                })
              }
            >
              {cell.getValue()}
            </Text>
          );
        },
        header: copyText.tableHeaderTenantName,
        size: 250,
      }),
      columnHelper.accessor("cloudProviderType", {
        cell: (cell) => copyText[`providerTypeLabel_${cell.getValue()}`],
        header: copyText.tableHeaderProviderType,
        size: 100,
      }),
      columnHelper.accessor("serviceType", {
        cell: (cell) => copyText[`serviceTypeLabel_${cell.getValue()}`],
        header: copyText.tableHeaderServiceType,
        size: 100,
      }),
      columnHelper.accessor("estimateValue", {
        cell: ({ column, getValue, row }) => {
          return (
            <MeasureCell
              applyMaxCharacters
              columnID={column?.id}
              currencyCode={row.original.estimateCurrency}
              unit={UnitType.CURRENCY}
              value={getValue()}
            />
          );
        },
        header: copyText.tableHeaderPotentialSavingsMonthly,
        meta: { align: "right" },
        size: 120,
      }),
    ],
    [props.recommendations]
  );

  const data = props.recommendations.map((rec) => {
    const grant = authenticatedUser.grants.find(
      (grant) => grant.tenantDocID === rec.tenantDocID
    );

    return { ...rec, hasTenantAccess: !!grant };
  });

  return (
    <Table
      columns={columns}
      compact
      data={data}
      initialState={{ sorting: [{ id: "estimateValue", desc: true }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

MspUsageRecommendationTable.INTERACTION_LINK_CLICKED =
  "MspChildUsageRecommendationTable.INTERACTION_LINK_CLICKED" as const;

interface InteractionLinkClicked {
  type: typeof MspUsageRecommendationTable.INTERACTION_LINK_CLICKED;
  tenantDocID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspUsageRecommendationTable {
  export type Interaction = InteractionLinkClicked;
}

export default MspUsageRecommendationTable;
