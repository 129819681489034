import { useTableContext } from "./Table";
import { RawValue } from "../types";
import { formatMeasureValueWithUnit, removeInvalidCharacters } from "../utils";
import { AVG_PIXEL_WIDTH_OF_CHARACTER } from "../constants";

function formatMeasure(
  value: RawValue,
  unit: string | undefined,
  options: {
    accounting?: boolean;
    currencyCode?: string;
    maxCharacters?: number;
  }
) {
  const { accounting, currencyCode, maxCharacters } = options;
  if (value === undefined) return "--";

  if (typeof value !== "number") {
    return value;
  }

  return formatMeasureValueWithUnit({
    accounting,
    currencyCode,
    currencyMaxCharacters: maxCharacters,
    unit,
    value,
  });
}

export function MeasureCell(props: {
  accounting?: boolean;
  applyMaxCharacters?: boolean;
  columnID?: string;
  currencyCode?: string;
  currentSize?: number;
  unit?: string;
  value: RawValue;
}) {
  const { columnSizeVars } = useTableContext();
  let maxCharacters: number | undefined = undefined;

  if (props.applyMaxCharacters && props.columnID) {
    const columnID = `--col-${removeInvalidCharacters(props.columnID)}-size`;
    const currentSize = props?.currentSize ?? columnSizeVars[columnID];
    maxCharacters = Math.floor(currentSize / AVG_PIXEL_WIDTH_OF_CHARACTER);
  }

  return formatMeasure(props.value, props.unit, {
    accounting: props.accounting,
    currencyCode: props.currencyCode,
    maxCharacters,
  });
}
