import * as Sentry from "@sentry/react";
import CubeQuery from "@ternary/api-lib/analytics/CubeQuery";
import DataQuery from "@ternary/api-lib/analytics/DataQuery";
import DataQueryExperimental from "@ternary/api-lib/analytics/DataQueryExperimental";
import { RawData } from "@ternary/api-lib/analytics/types";
import { EventReporter } from "@ternary/api-lib/telemetry";
import UError from "unilib-error";
import HTTPRequestSender from "../HTTPRequestSender";

const FAILED_CALCULATING_BYTES_MESSAGE = "Failed to calculate size in bytes";
const MAX_ROWS = 50000;
const QUERY_DURATION_MAX_TIME = 60000;

export type AnalyticsSchema = {
  schemaName: string;
  dimensions: { displayName: string }[];
  measures: { displayName: string }[];
};

type AnalyticsMetaData = {
  schemas: AnalyticsSchema[];
};

// TOOD: Bring these back when they are working in DA
type DatalligatorResult<TData> = {
  // has_more: boolean;
  // offset: number;
  response: TData[];
  // total: Record<string, number>;
};

type ForecastingResult<TData> = {
  forecast: TData[];
} & DatalligatorResult<TData>;

interface Config {
  analyticsSender: HTTPRequestSender;
  devMode: boolean;
  eventReporter: EventReporter;
}

export default class AnalyticsApiClient {
  private readonly _className = "AnalyticsApiClient";

  private readonly _analyticsSender: HTTPRequestSender;
  private readonly _devMode: boolean;
  private readonly _eventReporter: EventReporter;

  private _token: string | undefined;

  constructor(config: Config) {
    this._analyticsSender = config.analyticsSender;
    this._devMode = config.devMode;
    this._eventReporter = config.eventReporter;
  }

  public setToken(token: string) {
    this._token = token;
  }

  public async getMetadata(
    tenantID: string
  ): Promise<AnalyticsMetaData["schemas"]> {
    try {
      const result = (await this._analyticsSender.send(
        `/meta/focus?tenant_id=${tenantID}`
      )) as AnalyticsMetaData;

      return result.schemas;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new UError(`${this._className}.getMetadata/ERROR_UNEXPECTED`, {
        cause: error,
      });
    }
  }

  public async loadData<TData extends RawData = RawData>(
    tenantID: string,
    query: DataQuery
  ): Promise<DatalligatorResult<TData>> {
    try {
      const startTime = new Date().getTime();

      const result = (await this._analyticsSender.send(
        `/query/load?tenant_id=${tenantID}`,
        {
          method: "POST",
          body: query,
        }
      )) as DatalligatorResult<TData>;

      if (!this._devMode) {
        this._captureQueryData(query, result.response, startTime);
      }

      return result;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new UError(`${this._className}.loadData/ERROR_UNEXPECTED`, {
        cause: error,
      });
    }
  }

  public async loadDataExperimental<TData extends RawData = RawData>(
    tenantID: string,
    query: DataQueryExperimental
  ): Promise<ForecastingResult<TData>> {
    try {
      const startTime = new Date().getTime();

      const result = (await this._analyticsSender.send(
        `/x/query/load?tenant_id=${tenantID}`,
        {
          method: "POST",
          body: query,
        }
      )) as ForecastingResult<TData>;

      if (!this._devMode) {
        this._captureQueryData(query, result.response, startTime);
      }

      return result;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new UError(`${this._className}.loadData/ERROR_UNEXPECTED`, {
        cause: error,
      });
    }
  }

  // TODO: Remove this eventually. This is the old method from when we used cubejs.
  // Once we fully cut over to the new Datalligator API we won't need this anymore.

  /**  @deprecated This is going to be removed when we fully cut over to datalligator. Use loadData instead. */
  public async load(query: CubeQuery): Promise<RawData[]> {
    try {
      if (!this._token) return [];

      const startTime = new Date().getTime();

      Sentry.addBreadcrumb({
        category: "analytics",
        message: "Query for analytics load",
        level: "error",
        data: query,
      });

      const result = (await this._analyticsSender.send("/cubejs-api/v1/load", {
        headers: { Authorization: this._token },
        method: "POST",
        body: { query },
      })) as { data: RawData[] };

      if (!this._devMode) {
        this._captureQueryData(query, result.data, startTime);
      }

      return this._normalizeResult(result.data);
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new UError(`${this._className}.load/ERROR_UNEXPECTED`, {
        cause: error,
      });
    }
  }

  private _captureQueryData<TData = RawData>(
    query: CubeQuery | DataQuery,
    result: TData[],
    startTime: number
  ): void {
    const queryDuration = (new Date().getTime() - startTime) / 1000;
    const resultLength = result.length;

    let sizeInBytes: number;

    try {
      sizeInBytes = new TextEncoder().encode(JSON.stringify(result)).length;
    } catch {
      this._eventReporter.reportMessage(FAILED_CALCULATING_BYTES_MESSAGE);
      sizeInBytes = -1;
    }

    this._eventReporter.reportEvent("data_query", {
      dimensions: query.dimensions,
      duration_seconds: queryDuration,
      measures: query.measures,
      ...(typeof window !== "undefined"
        ? { page_path: window.location.pathname }
        : {}),
      response_length: resultLength,
      size_in_bytes: sizeInBytes,
      type: query instanceof CubeQuery ? "cube" : "datalligator",
    });

    if (queryDuration > QUERY_DURATION_MAX_TIME) {
      const message = `WARNING: Query duration exceeded ${QUERY_DURATION_MAX_TIME}ms.`;

      this._eventReporter.reportMessage(message, { context: { query } });
    }

    if (resultLength === MAX_ROWS) {
      this._eventReporter.reportEvent("QUERY_SIZE_LIMIT_EXCEEDED", {
        query,
      });
    }
  }

  // NOTE: This method assumes all field names are in the format `<schemaName>.<key>`.
  private _normalizeResult(result: RawData[]): RawData[] {
    return result.map((entry) =>
      Object.entries(entry).reduce((accum, [key, value]) => {
        const splitKey = key.split(".");

        if (splitKey[1] === "timestamp") {
          return { ...accum, timestamp: `${value}Z` };
        }

        return { ...accum, [splitKey[1]]: value };
      }, {})
    );
  }
}
