import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useTheme } from "@emotion/react";
import {
  faCalendar,
  faDollarSign,
  faEye,
  faEyeSlash,
  faFont,
  faGripVertical,
  faHashtag,
  faLeaf,
  faMicrochip,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import { DataSource, Operator } from "@ternary/api-lib/constants/enums";
import { Dimension, Measure } from "@ternary/api-lib/ui-lib/charts/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Text from "@ternary/web-ui-lib/components/Text";
import { uniq } from "lodash";
import React, { PropsWithChildren, useState } from "react";
import { operatorOptions } from "../../../constants";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import ConfirmationModal from "../../../ui-lib/components/ConfirmationModal";
import Dropdown from "../../../ui-lib/components/Dropdown";
import Select from "../../../ui-lib/components/Select";
import SelectDropdown, {
  Option,
} from "../../../ui-lib/components/SelectDropdown";
import { isOperator } from "../../../utils/typeGuards";
import { groupOptionsByPreferences } from "../../admin/utils";
import { getOperatorText } from "../../alert-tracking/utils";
import copyText from "../copyText";
import { Filter } from "../types";

const DIMENSION_NAME_MIN_WIDTH = 210;
const DIMENSION_NAME_MIN_WIDTH_EXPANDED = 135;
const FILTER_NAME_MIN_WIDTH = 235;
const FILTER_NAME_MIN_WIDTH_EXPANDED = 110;
const FILTER_VALUE_MIN_WIDTH = 250;
const FILTER_VALUE_MIN_WIDTH_EXPANDED = 95;
const MEASURE_NAME_MIN_WIDTH = 160;
const MEASURE_LETTER_PADDING = 25;
const SIDE_PANEL_WIDTH_OPEN = 350;

type DimensionPreference = {
  dataSource: DataSource;
  category: string;
  values: string[];
};

type MeasurePreference = {
  dataSource: DataSource;
  category: string;
  values: string[];
};

interface Props {
  availableDimensions: Dimension[];
  availableMeasures: Measure[];
  dataSource: DataSource;
  dimensionPreferences: DimensionPreference[];
  dimensions: Dimension[];
  dimensionValuesMap: { [key: string]: string[] };
  filters: Filter[];
  hiddenMeasures: string[];
  impactMode: boolean;
  isKPI: boolean;
  isLoadingDimensionValues: boolean;
  isLoadingDimensionPreferences: boolean;
  isLoadingMeasurePreferences: boolean;
  isSelectDisabled: boolean;
  measurePreferences: MeasurePreference[];
  measures: Measure[];
  width: number;
  onInteraction: (
    interaction: ReportBuilderSidePanelMainTab.Interaction
  ) => void;
}

export function ReportBuilderSidePanelMainTab(props: Props) {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const [isCarbonAndFiscalMode, setIsCarbonAndFiscalMode] = useState(true);
  const [customFilter, setCustomFilter] = useState<string[]>([]);

  //
  // Interaction Handlers
  //

  function handleAddDimension(values: string[]): void {
    const dimensions = values.reduce((accum: Dimension[], value) => {
      const dimension = props.availableDimensions.find(
        (dimension) => value === dimension.name
      );

      if (!dimension) return accum;

      return [...accum, dimension];
    }, []);

    props.onInteraction({
      type: ReportBuilderSidePanelMainTab.INTERACTION_ADD_DIMENSION_CLICKED,
      dimensions,
    });
  }

  function handleAddFilter(value: string): void {
    const dimension = props.availableDimensions.find(
      (dimension) => dimension.name === value
    );

    if (!dimension) return;

    props.onInteraction({
      type: ReportBuilderSidePanelMainTab.INTERACTION_ADD_FILTER_CLICKED,
      filter: {
        ...dimension,
        operator: Operator.EQUALS,
        values: [],
      },
    });
  }

  function handleAddMeasure(values: string[]): void {
    const measures = values.reduce((accum: Measure[], value) => {
      const measure = props.availableMeasures.find(
        (measure) => value === measure.name
      );

      if (!measure) return accum;

      return [...accum, measure];
    }, []);

    props.onInteraction({
      type: ReportBuilderSidePanelMainTab.INTERACTION_ADD_MEASURE_CLICKED,
      measures,
    });
  }

  function handleRemoveDimension(index: number): void {
    props.onInteraction({
      type: ReportBuilderSidePanelMainTab.INTERACTION_REMOVE_DIMENSION_CLICKED,
      index,
    });
  }

  function handleRemoveFilter(index: number): void {
    props.onInteraction({
      type: ReportBuilderSidePanelMainTab.INTERACTION_REMOVE_FILTER_CLICKED,
      index,
    });
  }

  function handleRemoveMeasure(index: number): void {
    props.onInteraction({
      type: ReportBuilderSidePanelMainTab.INTERACTION_REMOVE_MEASURE_CLICKED,
      index,
    });
  }

  function handleSelectSource(value: DataSource): void {
    props.onInteraction({
      type: ReportBuilderSidePanelMainTab.INTERACTION_SELECT_SOURCE_CLICKED,
      value,
    });
  }

  function handleUpdateDimension(value: string, index: number): void {
    const dimension = props.availableDimensions.find(
      (dimension) => dimension.name === value
    );

    if (!dimension) return;

    props.onInteraction({
      type: ReportBuilderSidePanelMainTab.INTERACTION_UPDATE_DIMENSION_CLICKED,
      dimension,
      index,
    });
  }

  function handleUpdateFilter(value: string, index: number): void {
    const dimension = props.availableDimensions.find(
      (dimension) => dimension.name === value
    );

    if (!dimension) return;

    props.onInteraction({
      type: ReportBuilderSidePanelMainTab.INTERACTION_UPDATE_FILTER_CLICKED,
      filter: {
        ...dimension,
        operator: Operator.EQUALS,
        values: [],
      },
      index,
    });
  }

  function handleUpdateFilterOperator(value: string, index: number): void {
    if (!isOperator(value)) return;

    props.onInteraction({
      type: ReportBuilderSidePanelMainTab.INTERACTION_UPDATE_FILTER_OPERATOR_CLICKED,
      operator: value,
      index,
    });
  }

  function handleUpdateFilterValues(
    value: string | string[],
    index: number
  ): void {
    props.onInteraction({
      type: ReportBuilderSidePanelMainTab.INTERACTION_UPDATE_FILTER_VALUES_CLICKED,
      values: Array.isArray(value) ? value : [value],
      index,
    });
  }

  function handleUpdateMeasure(value: string, index: number): void {
    const measure = props.availableMeasures.find(
      (measure) => measure.name === value
    );

    if (!measure) return;

    props.onInteraction({
      type: ReportBuilderSidePanelMainTab.INTERACTION_UPDATE_MEASURE_CLICKED,
      measure,
      index,
    });
  }

  //
  // Render
  //

  const dataSourceSelectOptions = [
    {
      label: copyText.selectOptionsFocusBilling,
      value: DataSource.FOCUS_BILLING,
    },
    {
      label: copyText.selectOptionsBilling,
      value: DataSource.BILLING,
    },
    {
      label: copyText.selectOptionsDetailedBilling,
      value: DataSource.DETAILED_BILLING,
    },
    {
      label: copyText.SelectOptionsCarbonEmissions,
      value: DataSource.CARBON_FOOTPRINT,
    },
    {
      label: copyText.selectOptionsGKEContainer,
      value: DataSource.KUBERNETES_CONTAINER_USAGE,
    },
    {
      label: copyText.selectOptionsGKENode,
      value: DataSource.KUBERNETES_NODE_USAGE,
    },
    {
      label: copyText.selectOptionsBigQueryUsage,
      value: DataSource.BIGQUERY_USAGE,
    },
    {
      label: copyText.selectOptionsCloudSQLCost,
      value: DataSource.CLOUD_SQL_COST,
    },
    {
      label: copyText.selectOptionsCloudSQLUsage,
      value: DataSource.CLOUD_SQL_INSTANCE_USAGE,
    },
    {
      label: copyText.selectOptionsEKSCost,
      value: DataSource.AWS_KUBERNETES_NODE_COST,
    },
    {
      label: copyText.selectOptionsEKSUsage,
      value: DataSource.AWS_KUBERNETES_NODE_USAGE,
    },
    ...(props.dataSource === DataSource.CLOUD_SQL_INSTANCE_USAGE_DAILY
      ? [
          {
            label: copyText.selectOptionsCloudSQLUsageDaily,
            value: DataSource.CLOUD_SQL_INSTANCE_USAGE_DAILY,
          },
        ]
      : []),

    ...(props.dataSource === DataSource.AWS_COMPUTE_VISIBILITY
      ? [
          {
            label: copyText.selectOptionsEC2,
            value: DataSource.AWS_COMPUTE_VISIBILITY,
          },
        ]
      : []),
    ...(props.dataSource === DataSource.GCP_COMPUTE_VISIBILITY
      ? [
          {
            label: copyText.selectOptionsGCE,
            value: DataSource.GCP_COMPUTE_VISIBILITY,
          },
        ]
      : []),
    ...(gatekeeper.hasSnowflakeIntegration
      ? [
          {
            label: copyText.selectOptionsSnowflakeWarehouseUsage,
            value: DataSource.SNOWFLAKE_WAREHOUSE_USAGE,
          },
        ]
      : []),
  ];

  function filterDataSource(dataSourceOptions) {
    if (!gatekeeper.isCarbonFootprintConfigured) {
      return dataSourceOptions.filter(
        (option) => option.value !== DataSource.CARBON_FOOTPRINT
      );
    }

    return dataSourceOptions;
  }

  const dataSourceSelect = (
    <Select
      compact
      disabled={props.isSelectDisabled}
      options={filterDataSource(dataSourceSelectOptions)}
      value={dataSourceSelectOptions.find((option) => {
        return option.value === props.dataSource;
      })}
      onChange={(option) => option && handleSelectSource(option.value)}
    />
  );

  const measureOptions = props.availableMeasures.reduce(
    (accum: Option[], availableMeasure) =>
      props.measures.some((measure) => measure.name === availableMeasure.name)
        ? accum
        : [
            ...accum,
            {
              label: availableMeasure.name,
              value: availableMeasure.name,
            },
          ],
    []
  );

  const groupedMeasureOptions = props.isLoadingMeasurePreferences
    ? []
    : groupOptionsByPreferences(
        measureOptions,
        props.measurePreferences,
        props.dataSource,
        gatekeeper.isMeasurePreferenceAdmin
      );

  const dimensionOptions = props.availableDimensions.reduce(
    (accum: Option[], availableDimension) =>
      props.dimensions.some(
        (dimension) => dimension.name === availableDimension.name
      )
        ? accum
        : [
            ...accum,
            {
              label: availableDimension.name,
              value: availableDimension.name,
            },
          ],
    []
  );

  const groupedDimensionOptions = props.isLoadingDimensionPreferences
    ? []
    : groupOptionsByPreferences(
        dimensionOptions,
        props.dimensionPreferences,
        props.dataSource,
        gatekeeper.isLabelPreferenceAdmin
      );

  const filterOptions = props.availableDimensions.reduce(
    (accum: Option[], availableDimension) =>
      props.filters.some((filter) => filter.name === availableDimension.name)
        ? accum
        : [
            ...accum,
            {
              label: availableDimension.name,
              value: availableDimension.name,
            },
          ],
    []
  );

  const groupedFilterOptions = props.isLoadingDimensionPreferences
    ? []
    : groupOptionsByPreferences(
        filterOptions,
        props.dimensionPreferences,
        props.dataSource,
        gatekeeper.isLabelPreferenceAdmin
      );

  function Sortable(props: PropsWithChildren<{ id: string }>) {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: props.id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <div
        data-no-dnd="true"
        ref={setNodeRef}
        style={style}
        {...listeners}
        {...attributes}
      >
        {props.children}
      </div>
    );
  }

  function renderMeasureDndList() {
    const mouseSensor = useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    });

    const keyboardSensor = useSensor(KeyboardSensor);

    const sensors = useSensors(mouseSensor, keyboardSensor);

    function handleOnDragEnd(event: DragEndEvent) {
      const { active, over } = event;

      const measureNames = props.measures.map((measure) => measure.name);

      if (over && active.id !== over.id) {
        const activeIndex = measureNames.indexOf(String(active.id));
        const overIndex = measureNames.indexOf(String(over.id));

        const sortedMeasureNames = arrayMove(
          measureNames,
          activeIndex,
          overIndex
        );

        const sortedMeasures = props.measures.sort(
          (a, b) =>
            sortedMeasureNames.indexOf(a.name) -
            sortedMeasureNames.indexOf(b.name)
        );

        props.onInteraction({
          type: ReportBuilderSidePanelMainTab.INTERACTION_REORDER_MEASURES,
          measures: sortedMeasures,
        });
      }
    }

    const items = props.measures.map((measure) => measure.name);

    const truncateWidth =
      props.width > SIDE_PANEL_WIDTH_OPEN
        ? props.width - MEASURE_NAME_MIN_WIDTH - MEASURE_LETTER_PADDING
        : MEASURE_NAME_MIN_WIDTH;

    return (
      <DndContext
        collisionDetection={closestCenter}
        sensors={sensors}
        onDragEnd={handleOnDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {props.measures.map((measure, i) => (
            <Sortable key={measure.name} id={measure.name}>
              <Flex
                borderRadius={theme.borderRadius_2}
                justifyContent="space-between"
                marginBottom={theme.space_sm}
                width="100%"
              >
                <Flex alignItems="center">
                  <Box marginRight={theme.space_md} cursor="grab">
                    <Icon
                      color={theme.text_color_secondary}
                      draggable
                      icon={faGripVertical}
                      size="xs"
                    />
                  </Box>
                  <Box marginRight={theme.space_xs} width={10}>
                    <Text>{String.fromCharCode(i + 65)}</Text>
                  </Box>
                  <SelectDropdown
                    disabled={props.isKPI && i !== 0}
                    isLoading={props.isLoadingMeasurePreferences}
                    options={groupedMeasureOptions}
                    placement="bottom-end"
                    onChange={(value) => handleUpdateMeasure(value, i)}
                  >
                    <Button
                      disabled={props.isKPI && i !== 0}
                      iconStart={<Icon icon={getMeasureIcon(measure.unit)} />}
                      marginRight={theme.space_xs}
                      secondary
                      size="tiny"
                    >
                      <Text truncate={truncateWidth}>{measure.name}</Text>
                    </Button>
                  </SelectDropdown>
                </Flex>

                <Flex alignItems="center">
                  <Button
                    iconStart={
                      <Icon
                        icon={
                          props.hiddenMeasures.some(
                            (hiddenMeasure) => hiddenMeasure === measure.name
                          )
                            ? faEyeSlash
                            : faEye
                        }
                      />
                    }
                    marginRight={theme.space_xs}
                    size="tiny"
                    width={10}
                    onClick={() =>
                      props.onInteraction({
                        type: ReportBuilderSidePanelMainTab.INTERACTION_TOGGLE_MEASURE,
                        measure: measure.name,
                      })
                    }
                  />
                  <Button
                    iconStart={<Icon icon={faTrashAlt} />}
                    size="tiny"
                    onClick={() => handleRemoveMeasure(i)}
                  />
                </Flex>
              </Flex>
            </Sortable>
          ))}
        </SortableContext>
      </DndContext>
    );
  }

  function renderDimensionsDndList() {
    const mouseSensor = useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    });

    const keyboardSensor = useSensor(KeyboardSensor);

    const sensors = useSensors(mouseSensor, keyboardSensor);

    function handleOnDragEnd(event: DragEndEvent) {
      const { active, over } = event;

      const dimensionNames = props.dimensions.map(
        (dimension) => dimension.name
      );

      if (over && active.id !== over.id) {
        const activeIndex = dimensionNames.indexOf(String(active.id));
        const overIndex = dimensionNames.indexOf(String(over.id));

        const sortedDimensionNames = arrayMove(
          dimensionNames,
          activeIndex,
          overIndex
        );

        const sortedDimensions = props.dimensions.sort(
          (a, b) =>
            sortedDimensionNames.indexOf(a.name) -
            sortedDimensionNames.indexOf(b.name)
        );

        props.onInteraction({
          type: ReportBuilderSidePanelMainTab.INTERACTION_REORDER_DIMENSIONS,
          dimensions: sortedDimensions,
        });
      }
    }

    const items = props.dimensions.map((dimension) => dimension.name);

    const truncateWidth =
      props.width > SIDE_PANEL_WIDTH_OPEN
        ? props.width - DIMENSION_NAME_MIN_WIDTH_EXPANDED
        : DIMENSION_NAME_MIN_WIDTH;

    return (
      <DndContext
        collisionDetection={closestCenter}
        sensors={sensors}
        onDragEnd={handleOnDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {props.dimensions.map((dimension, i) => (
            <Sortable key={dimension.name} id={dimension.name}>
              <Flex
                borderRadius={theme.borderRadius_2}
                justifyContent="space-between"
                marginBottom={theme.space_sm}
                width="100%"
              >
                <Flex alignItems="center">
                  <Box marginRight={theme.space_md} cursor="grab">
                    <Icon
                      color={theme.text_color_secondary}
                      draggable
                      icon={faGripVertical}
                      size="xs"
                    />
                  </Box>
                  <SelectDropdown
                    disabled={props.isKPI}
                    isLoading={props.isLoadingDimensionPreferences}
                    options={groupedDimensionOptions}
                    placement="bottom-end"
                    onChange={(value) => handleUpdateDimension(value, i)}
                  >
                    <Button
                      disabled={props.isKPI}
                      iconStart={
                        <Icon icon={dimension.isDate ? faCalendar : faFont} />
                      }
                      marginRight={theme.space_xs}
                      secondary
                      size="tiny"
                    >
                      <Text truncate={truncateWidth}>{dimension.name}</Text>
                    </Button>
                  </SelectDropdown>
                </Flex>
                <Button
                  iconStart={<Icon icon={faTrashAlt} />}
                  size="tiny"
                  onClick={() => handleRemoveDimension(i)}
                />
              </Flex>
            </Sortable>
          ))}
        </SortableContext>
      </DndContext>
    );
  }

  const nameTruncateWidth =
    props.width > SIDE_PANEL_WIDTH_OPEN
      ? props.width - FILTER_NAME_MIN_WIDTH_EXPANDED
      : FILTER_NAME_MIN_WIDTH;

  const valueTruncateWidth =
    props.width > SIDE_PANEL_WIDTH_OPEN
      ? props.width - FILTER_VALUE_MIN_WIDTH_EXPANDED
      : FILTER_VALUE_MIN_WIDTH;

  return (
    <Flex direction="column" paddingHorizontal={theme.space_md} width="100%">
      {authenticatedUser.settings.fiscalMode &&
        props.impactMode &&
        isCarbonAndFiscalMode && (
          <ConfirmationModal
            message={copyText.disableFiscalMode}
            title={copyText.disableFiscalModeTitle}
            variant="danger"
            onCancel={() => setIsCarbonAndFiscalMode(false)}
            onConfirm={() => setIsCarbonAndFiscalMode(false)}
          />
        )}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom={theme.space_sm}
        marginTop={theme.space_sm}
      >
        <Text
          appearance="h4"
          color={props.impactMode ? theme.eco_impact : undefined}
          marginRight={theme.space_md}
        >
          {copyText.selectSourceTitle}
        </Text>
        <Box width={250}>
          {props.isSelectDisabled ? (
            <Tooltip content={copyText.selectTooltipText}>
              {dataSourceSelect}
            </Tooltip>
          ) : (
            dataSourceSelect
          )}
        </Box>

        {props.impactMode ? (
          <Box>
            <Icon icon={faLeaf} color={theme.eco_impact} />
          </Box>
        ) : null}
      </Flex>

      <Box width="100%">
        {/* Measures */}

        <Box marginBottom={theme.space_sm}>
          <Box marginBottom={theme.space_sm}>
            <SelectDropdown
              closeOnSubmit
              disabled={props.isKPI && props.measures.length > 0}
              isLoading={props.isLoadingMeasurePreferences}
              isMulti
              options={groupedMeasureOptions}
              placement="bottom-end"
              selectedValues={props.measures.map((measure) => measure.name)}
              onChange={handleAddMeasure}
            >
              <Button
                disabled={props.isKPI && props.measures.length > 0}
                fullWidth
                size="small"
              >
                <Flex alignItems="center" justifyContent="space-between">
                  <Text
                    color={props.impactMode ? theme.eco_impact : undefined}
                    fontSize={theme.fontSize_base}
                    fontWeight={theme.fontWeight_regular}
                  >
                    {copyText.measuresHeader}
                  </Text>
                  <Flex alignItems="center">
                    <Icon icon={faPlus} height={9} />
                    <Text>{copyText.addButtonLabel}</Text>
                  </Flex>
                </Flex>
              </Button>
            </SelectDropdown>
          </Box>

          <Flex direction="column" justifyContent="flex-start">
            {renderMeasureDndList()}
          </Flex>
        </Box>

        {/* Dimensions */}

        <Box marginBottom={theme.space_sm}>
          <Box marginBottom={theme.space_sm}>
            <SelectDropdown
              closeOnSubmit
              disabled={
                props.isKPI ||
                (props.dataSource === DataSource.DETAILED_BILLING &&
                  props.dimensions.length >= 3)
              }
              isLoading={props.isLoadingDimensionPreferences}
              isMulti
              options={groupedDimensionOptions}
              placement="bottom-end"
              selectedValues={props.dimensions.map(
                (dimension) => dimension.name
              )}
              onChange={handleAddDimension}
            >
              <Button disabled={props.isKPI} fullWidth size="small">
                <Flex alignItems="center" justifyContent="space-between">
                  <Text
                    color={props.impactMode ? theme.eco_impact : undefined}
                    fontSize={theme.fontSize_base}
                    fontWeight={theme.fontWeight_regular}
                  >
                    {copyText.dimensionsHeader}
                  </Text>
                  <Flex alignItems="center">
                    <Icon icon={faPlus} />
                    <Text>{copyText.addButtonLabel}</Text>
                  </Flex>
                </Flex>
              </Button>
            </SelectDropdown>
          </Box>
          <Flex direction="column" justifyContent="flex-start">
            {renderDimensionsDndList()}
          </Flex>
        </Box>

        {/* Filters */}

        <Box marginBottom={theme.space_sm}>
          <Box marginBottom={theme.space_sm}>
            <SelectDropdown
              isLoading={props.isLoadingDimensionPreferences}
              options={groupedFilterOptions}
              placement="bottom-end"
              onBlur={() => {
                props.onInteraction({
                  type: ReportBuilderSidePanelMainTab.INTERACTION_FILTER_DROPDOWN_BLUR_TRIGGERED,
                });
              }}
              onChange={handleAddFilter}
              onClick={() => {
                props.onInteraction({
                  type: ReportBuilderSidePanelMainTab.INTERACTION_FILTER_DROPDOWN_TRIGGER_CLICKED,
                });
              }}
            >
              <Button fullWidth size="small">
                <Flex alignItems="center" justifyContent="space-between">
                  <Text
                    color={props.impactMode ? theme.eco_impact : undefined}
                    fontSize={theme.fontSize_base}
                    fontWeight={theme.fontWeight_regular}
                  >
                    {copyText.filtersHeader}
                  </Text>
                  <Flex alignItems="center">
                    <Icon icon={faPlus} />
                    <Text>{copyText.addButtonLabel}</Text>
                  </Flex>
                </Flex>
              </Button>
            </SelectDropdown>
          </Box>

          <Flex direction="column">
            {props.filters.map((filter, i) => {
              const operatorOption = operatorOptions.find(
                (option) => option.value === filter.operator
              );

              const currentValues = filter.values ? filter.values : [];
              const dimensionValues =
                props.dimensionValuesMap[filter.name] ?? [];

              const valueOptions = uniq([
                ...currentValues.slice().sort(),
                ...customFilter.slice().sort(),
                ...dimensionValues.slice().sort(),
              ]).map((value) => ({ label: value, value }));

              return (
                <Box key={i} marginBottom={theme.space_sm}>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom={theme.space_xs}
                  >
                    <SelectDropdown
                      isLoading={props.isLoadingDimensionPreferences}
                      options={groupedFilterOptions}
                      placement="bottom-end"
                      onBlur={() => {
                        props.onInteraction({
                          type: ReportBuilderSidePanelMainTab.INTERACTION_FILTER_DROPDOWN_BLUR_TRIGGERED,
                        });
                      }}
                      onChange={(value) => handleUpdateFilter(value, i)}
                      onClick={() => {
                        props.onInteraction({
                          type: ReportBuilderSidePanelMainTab.INTERACTION_FILTER_DROPDOWN_TRIGGER_CLICKED,
                        });
                      }}
                    >
                      <Button
                        iconStart={
                          <Icon icon={filter.isDate ? faCalendar : faFont} />
                        }
                        marginRight={theme.space_xs}
                        secondary
                        size="tiny"
                      >
                        <Text truncate={nameTruncateWidth}>{filter.name}</Text>
                      </Button>
                    </SelectDropdown>
                    <Button
                      iconStart={<Icon icon={faTrashAlt} />}
                      size="tiny"
                      onClick={() => handleRemoveFilter(i)}
                    />
                  </Flex>
                  <Flex>
                    <Dropdown
                      defaultSelectedOption={operatorOptions[0]}
                      options={operatorOptions.map((option) => ({
                        ...option,
                        onClick: (value: string) =>
                          handleUpdateFilterOperator(value, i),
                      }))}
                      placement="bottom-end"
                      selectedOption={operatorOption}
                    >
                      <Button
                        marginRight={theme.space_xs}
                        secondary
                        size="tiny"
                      >
                        {getOperatorText(operatorOption?.value)}
                      </Button>
                    </Dropdown>
                    {!([Operator.NOT_SET, Operator.SET] as Operator[]).includes(
                      filter.operator
                    ) && (
                      <SelectDropdown
                        closeOnSubmit
                        createDialogue={(input) =>
                          copyText.addFilterValueDialogue.replace(
                            "%VALUE%",
                            input
                          )
                        }
                        createOption={(inputValue) => ({
                          label: inputValue,
                          value: inputValue,
                        })}
                        isLoading={props.isLoadingDimensionValues}
                        isCreatable
                        isMulti={(
                          [
                            Operator.CONTAINS,
                            Operator.EQUALS,
                            Operator.NOT_EQUALS,
                          ] as Operator[]
                        ).includes(filter.operator)}
                        options={valueOptions}
                        placement="bottom-end"
                        selectedValues={filter.values ?? undefined}
                        sortSelected
                        submitButtonText={copyText.applyFilterButtonLabel}
                        onBlur={() => {
                          props.onInteraction({
                            type: ReportBuilderSidePanelMainTab.INTERACTION_FILTER_DROPDOWN_BLUR_TRIGGERED,
                          });
                        }}
                        onChange={(value: string | string[]) => {
                          handleUpdateFilterValues(value, i);
                        }}
                        onCreateOption={(value: string) =>
                          setCustomFilter((currentState) => [
                            ...currentState,
                            value,
                          ])
                        }
                        onClick={() => {
                          props.onInteraction({
                            type: ReportBuilderSidePanelMainTab.INTERACTION_FILTER_DROPDOWN_TRIGGER_CLICKED,
                          });
                        }}
                      >
                        {filter.values && (
                          <Button
                            marginRight={theme.space_xs}
                            secondary
                            size="tiny"
                          >
                            <Text truncate={valueTruncateWidth}>
                              {filter.values.length === 0
                                ? copyText.selectValueLabel
                                : getFilterValuesButtonLabel(filter.values)}
                            </Text>
                          </Button>
                        )}
                      </SelectDropdown>
                    )}
                  </Flex>
                </Box>
              );
            })}
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}

function getFilterValuesButtonLabel(values: string[]): string {
  return values
    .reduce((accum: string[], value) => {
      if (accum.length < 3) {
        return [...accum, value];
      }

      if (accum.length === 3) {
        return [...accum, `${values.length - 3} ${copyText.moreOptionsLabel}`];
      }

      return accum;
    }, [])
    .join(" or ");
}

function getMeasureIcon(unit: string | undefined) {
  switch (unit) {
    case UnitType.BYTES: {
      return faMicrochip;
    }
    case UnitType.CURRENCY: {
      return faDollarSign;
    }
    case UnitType.KILOGRAMS: {
      return faLeaf;
    }
    default: {
      return faHashtag;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ReportBuilderSidePanelMainTab {
  export const INTERACTION_ADD_DIMENSION_CLICKED = `ReportBuilderSidePanelMainTab.INTERACTION_ADD_DIMENSION_CLICKED`;
  export const INTERACTION_ADD_FILTER_CLICKED = `ReportBuilderSidePanelMainTab.INTERACTION_ADD_FILTER_CLICKED`;
  export const INTERACTION_ADD_MEASURE_CLICKED = `ReportBuilderSidePanelMainTab.INTERACTION_ADD_MEASURE_CLICKED`;
  export const INTERACTION_FILTER_DROPDOWN_BLUR_TRIGGERED = `ReportBuilderSidePanelMainTab.INTERACTION_FILTER_DROPDOWN_BLUR_TRIGGERED`;
  export const INTERACTION_FILTER_DROPDOWN_TRIGGER_CLICKED = `ReportBuilderSidePanelMainTab.INTERACTION_FILTER_DROPDOWN_TRIGGER_CLICKED`;
  export const INTERACTION_REMOVE_DIMENSION_CLICKED = `ReportBuilderSidePanelMainTab.INTERACTION_REMOVE_DIMENSION_CLICKED`;
  export const INTERACTION_REMOVE_FILTER_CLICKED = `ReportBuilderSidePanelMainTab.INTERACTION_REMOVE_FILTER_CLICKED`;
  export const INTERACTION_REMOVE_MEASURE_CLICKED = `ReportBuilderSidePanelMainTab.INTERACTION_REMOVE_MEASURE_CLICKED`;
  export const INTERACTION_REORDER_DIMENSIONS = `ReportBuilderSidePanelMainTab.INTERACTION_REORDER_DIMENSIONS`;
  export const INTERACTION_REORDER_MEASURES = `ReportBuilderSidePanelMainTab.INTERACTION_REORDER_MEASURES`;
  export const INTERACTION_SELECT_SOURCE_CLICKED = `ReportBuilderSidePanelMainTab.INTERACTION_SELECT_SOURCE_CLICKED`;
  export const INTERACTION_TOGGLE_MEASURE = `ReportBuilderSidePanelMainTab.INTERACTION_TOGGLE_MEASURE`;
  export const INTERACTION_UPDATE_DIMENSION_CLICKED = `ReportBuilderSidePanelMainTab.INTERACTION_UPDATE_DIMENSION_CLICKED`;
  export const INTERACTION_UPDATE_FILTER_CLICKED = `ReportBuilderSidePanelMainTab.INTERACTION_UPDATE_FILTER_CLICKED`;
  export const INTERACTION_UPDATE_FILTER_OPERATOR_CLICKED = `ReportBuilderSidePanelMainTab.INTERACTION_UPDATE_FILTER_OPERATOR_CLICKED`;
  export const INTERACTION_UPDATE_FILTER_VALUES_CLICKED = `ReportBuilderSidePanelMainTab.INTERACTION_UPDATE_FILTER_VALUES_CLICKED`;
  export const INTERACTION_UPDATE_MEASURE_CLICKED = `ReportBuilderSidePanelMainTab.INTERACTION_UPDATE_MEASURE_CLICKED`;

  interface InteractionAddDimensionClicked {
    type: typeof INTERACTION_ADD_DIMENSION_CLICKED;
    dimensions: Dimension[];
  }

  interface InteractionAddFilterClicked {
    type: typeof INTERACTION_ADD_FILTER_CLICKED;
    filter: Filter;
  }

  interface InteractionAddMeasureClicked {
    type: typeof INTERACTION_ADD_MEASURE_CLICKED;
    measures: Measure[];
  }

  interface InteractionFilterDropdownBlurTriggered {
    type: typeof INTERACTION_FILTER_DROPDOWN_BLUR_TRIGGERED;
  }

  interface InteractionFilterDropdownTriggerClicked {
    type: typeof INTERACTION_FILTER_DROPDOWN_TRIGGER_CLICKED;
  }

  interface InteractionRemoveDimensionClicked {
    type: typeof INTERACTION_REMOVE_DIMENSION_CLICKED;
    index: number;
  }

  interface InteractionRemoveFilterClicked {
    type: typeof INTERACTION_REMOVE_FILTER_CLICKED;
    index: number;
  }

  interface InteractionRemoveMeasureClicked {
    type: typeof INTERACTION_REMOVE_MEASURE_CLICKED;
    index: number;
  }

  interface InteractionReorderDimensions {
    type: typeof INTERACTION_REORDER_DIMENSIONS;
    dimensions: Dimension[];
  }

  interface InteractionReorderMeasures {
    type: typeof INTERACTION_REORDER_MEASURES;
    measures: Measure[];
  }

  interface InteractionSelectSourceClicked {
    type: typeof INTERACTION_SELECT_SOURCE_CLICKED;
    value: DataSource;
  }

  interface InteractionToggleMeasure {
    type: typeof INTERACTION_TOGGLE_MEASURE;
    measure: string;
  }

  interface InteractionUpdateDimensionClicked {
    type: typeof INTERACTION_UPDATE_DIMENSION_CLICKED;
    dimension: Dimension;
    index: number;
  }

  interface InteractionUpdateFilterClicked {
    type: typeof INTERACTION_UPDATE_FILTER_CLICKED;
    filter: Filter;
    index: number;
  }

  interface InteractionUpdateFilterOperatorClicked {
    type: typeof INTERACTION_UPDATE_FILTER_OPERATOR_CLICKED;
    operator: Operator;
    index: number;
  }

  interface InteractionUpdateFilterValuesClicked {
    type: typeof INTERACTION_UPDATE_FILTER_VALUES_CLICKED;
    values: string[];
    index: number;
  }

  interface InteractionUpdateMeasureClicked {
    type: typeof INTERACTION_UPDATE_MEASURE_CLICKED;
    measure: Measure;
    index: number;
  }

  export type Interaction =
    | InteractionAddDimensionClicked
    | InteractionAddFilterClicked
    | InteractionAddMeasureClicked
    | InteractionFilterDropdownBlurTriggered
    | InteractionFilterDropdownTriggerClicked
    | InteractionRemoveDimensionClicked
    | InteractionRemoveFilterClicked
    | InteractionRemoveMeasureClicked
    | InteractionReorderDimensions
    | InteractionReorderMeasures
    | InteractionSelectSourceClicked
    | InteractionToggleMeasure
    | InteractionUpdateDimensionClicked
    | InteractionUpdateFilterClicked
    | InteractionUpdateFilterOperatorClicked
    | InteractionUpdateFilterValuesClicked
    | InteractionUpdateMeasureClicked;
}

export default ReportBuilderSidePanelMainTab;
