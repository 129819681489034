import { MeasureWithUnit } from "@/constants";
import { useQueryClient } from "@tanstack/react-query";
import {
  availableMeasuresMap,
  awsBillingMeasures,
  UnitType,
} from "@ternary/api-lib/constants/analytics";
import { CloudCapability, DataSource } from "@ternary/api-lib/constants/enums";
import { DataIntegrationEntity } from "@ternary/api-lib/core/types";
import { Measure } from "@ternary/api-lib/ui-lib/charts/types";
import { AnalyticsSchema } from "../api/analytics/AnalyticsApiClient";
import queryKeys from "../features/admin/hooks/queryKeys";
import { isAWSIntegration } from "../utils/typeGuards";
import useAuthenticatedUser from "./useAuthenticatedUser";
import useGatekeeper from "./useGatekeeper";

const customCostMeasures = ["customNetCost", "customNetAmortizedCost"];

export default function useAvailableMeasuresByDataSource(
  dataSource: DataSource
): MeasureWithUnit[] {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const queryClient = useQueryClient();

  // Load focus measures from analytics metadata.
  if (dataSource === DataSource.FOCUS_BILLING) {
    const metadataArray = queryClient.getQueryData<AnalyticsSchema[]>([
      "analyticsMetadata",
      authenticatedUser.tenant.id,
    ]);

    if (!metadataArray) return [];

    const metadata = metadataArray.find(
      (metadata) => metadata.schemaName === DataSource.FOCUS_BILLING
    );

    if (!metadata) return [];

    return metadata.measures
      .map((measure) => ({
        name: measure.displayName,
        unit: UnitType.STANDARD,
      }))
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
  }

  let defaultMeasures = isDataSourceWithMeasure(dataSource)
    ? availableMeasuresMap[dataSource]
    : [];

  if (dataSource === DataSource.BILLING && !gatekeeper.isMspParentTenant) {
    defaultMeasures = defaultMeasures.filter(
      (measure) => measure.name !== "absoluteCreditsResellerMargin"
    );
  }

  const integrations = queryClient.getQueryData<DataIntegrationEntity[]>(
    queryKeys.dataIntegrations
  );

  if (
    dataSource === DataSource.BILLING &&
    integrations &&
    hasAWSCloudWithCURAccess(integrations)
  ) {
    return [...defaultMeasures, ...awsBillingMeasures];
  }

  return defaultMeasures.filter((measure: Measure) => {
    return (
      // Include all measures if custom pricing is enabled, and if it's not, filter out
      // the measures in `customCostMeasures`.
      gatekeeper.canApplyCustomPricing ||
      customCostMeasures.indexOf(measure.name) === -1
    );
  });
}

function isDataSourceWithMeasure(
  dataSource: DataSource
): dataSource is keyof typeof availableMeasuresMap {
  return Boolean(availableMeasuresMap[dataSource]);
}

function hasAWSCloudWithCURAccess(integrations: DataIntegrationEntity[]) {
  const awsCloudsWithCUR = integrations.filter((integration) => {
    if (!isAWSIntegration(integration)) return false;

    if (
      !integration.validations.find(
        (validation) =>
          validation.name === CloudCapability.BILL_DATA_AWS &&
          validation.success
      )
    ) {
      return false;
    }

    return true;
  });

  return awsCloudsWithCUR.length > 0;
}
