import { useQueryClient } from "@tanstack/react-query";
import { defaultDimensionsMap } from "@ternary/api-lib/constants/analytics";
import { DataSource } from "@ternary/api-lib/constants/enums";
import { AnalyticsSchema } from "../api/analytics/AnalyticsApiClient";
import { LabelMapsEntity } from "../api/core/types";
import useAuthenticatedUser from "./useAuthenticatedUser";

/**
 * Custom hook that derives all available dimensions by combining default dimensions
 * with custom dimensions for a given DataSource.
 * @param {DataSource} dataSource
 */

export default function useAvailableDimensionsByDataSource(
  dataSource: DataSource
): string[] {
  const authenticatedUser = useAuthenticatedUser();
  const queryClient = useQueryClient();

  // Load focus dimensions from analytics metadata.
  if (dataSource === DataSource.FOCUS_BILLING) {
    const metadataArray = queryClient.getQueryData<AnalyticsSchema[]>([
      "analyticsMetadata",
      authenticatedUser.tenant.id,
    ]);

    if (!metadataArray) return [];

    const metadata = metadataArray.find(
      (metadata) => metadata.schemaName === DataSource.FOCUS_BILLING
    );

    if (!metadata) return [];

    return metadata.dimensions.map((dimension) => dimension.displayName).sort();
  }

  const labelMaps = queryClient.getQueryData<LabelMapsEntity>(["labelMaps"]);

  const defaultDimensions: string[] = defaultDimensionsMap[dataSource];

  if (!labelMaps) return defaultDimensions;

  if (!labelMaps[dataSource]) return defaultDimensions;

  const labelsKeyedByDimension = labelMaps[dataSource];

  const customDimensions = Object.keys(labelsKeyedByDimension);

  return defaultDimensions
    .concat(customDimensions)
    .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
}
